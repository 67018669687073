import { FaRegEdit } from "react-icons/fa";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationService from "../../../services/organization.service";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import OrganizationDropdownList from "../../../components/Admin/Organization/OrganizationDropdownList";
import UserService from "../../../services/user.service";

export default function OrganisationManagementView(){
    const {id} = useParams();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [loading, setLoading] = useState(false);
    const [selectedHr, setSelectedHr] = useState(null);
    const [organisation, setOrganisation] = useState({});
    const [organisations, setOrganisations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    const editJd = () => navigate(`/dashboard/organisations-management/${id}/update`);

    useEffect(() => {
        async function fetchOrganizations(){
            try {
                setLoading(true);
                const response = await OrganizationService.getOrganizations();
                setLoading(false);
                if(response.status === 200)
                    setOrganisations(response.data?.['data'])
            } catch (error) {
                setLoading(false);
            }
        }
        fetchOrganizations();
    }, [])

    async function init(){
        try {
            setLoading(true);
            const response = await OrganizationService.getOrganization(id);
            if(response.status === 200)
                setOrganisation(response.data?.['data'])
        } catch (error) {
            setLoading(false);
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        init();
    }, [])

    const changeOrg = async () => {
        try {
            setLoading(true);
            const response = await UserService.updateUser(selectedHr['_id'], {
                organization: selectedOrganization['_id']
            })
            setLoading(false);
            if(response.status === 200) init();
        } catch (error) {
            setLoading(false);
        }
    }

    const selectOrganization = (data) => {
        return (
            <div
                onClick={() => setSelectedHr(data)}>
                <OrganizationDropdownList 
                    data={organisations}
                    setSelectedOrganization={setSelectedOrganization}
                    label={selectedOrganization && data?._id === selectedHr?.['_id'] ? selectedOrganization?.['name'] : 'Select Org'}
                />
            </div>
        )
    }

    if(loading || !organisation){
        return (
            <div className="flex flex-col min-h-full gap-4 p-5">
                <div className='h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center'>
                    <div className='w-40'>
                        <LoadingComponent />
                    </div>
                </div>
            </div>
        )
    }

    const renderOrganization = (data) => {
        return (
            <div className="flex gap-2 items-center">
                <img
                    alt='logo'
                    className='h-10 w-10'
                    src={data?.['organization']?.['logo']}
                />
                <h2>{data?.['organization']?.['name']}</h2>
            </div>
        )
    }

    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-primary cursor-pointer">
                    <MdKeyboardArrowLeft 
                        size={26}
                        onClick={goBack}
                    />
                    <h1 className='text-xl text-primary font-bold'>Organization Details</h1>
                </div>

                <div className="flex gap-10 items-center">
                    {selectedOrganization && (
                        <button 
                            onClick={changeOrg}
                            className="bg-primary text-white rounded-md h-9 px-5 text-sm">
                            Save changes
                        </button>
                    )}
                    <FaRegEdit 
                        size={26}
                        onClick={editJd.bind(this)}
                        className="text-blue cursor-pointer"
                    />
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-12'>
                <div className='flex justify-between gap-10'>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <img
                            alt='logo'
                            className='h-16 w-16'
                            src={organisation['logo']}
                        />
                    </div>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <h3 className="text-sm font-semibold text-darkGray">Organization Name</h3>
                        <span className="font-normal">{organisation['name']}</span>
                    </div>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <h3 className="text-sm font-semibold text-darkGray">Industry</h3>
                        <span className="font-normal">{organisation['industry']}</span>
                    </div>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <h3 className="text-sm font-semibold text-darkGray">Website</h3>
                        <span className="font-normal">{organisation['website']}</span>
                    </div>
                </div>
                <div className='flex justify-between gap-10'>
                    <div className="flex-col flex gap-2 ">
                        <h3 className="text-sm font-semibold text-darkGray">Organization Information</h3>
                        <p className="font-normal">{organisation['information']}</p>
                    </div>
                </div>
            </div>
            <div className='bg-white py-5 rounded-md flex flex-col gap-4'>
                <h2 className="font-semibold text-blue px-3">Hr List</h2>
                <DataTable 
                    value={organisation['users']}
                    loading={loading}
                    onRowClick={({data}) => {
                        // navigate(`/dashboard/job-description/${data._id}`, {state: data})
                    }}
                    emptyMessage="No hr available"
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'>
                    <Column 
                        field="firstName" 
                        header="FirstName" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="lastName" 
                        header="LastName" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="email" 
                        header="Email" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="mobile" 
                        header="Mobile" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column  
                        header="Organization"
                        field="organization.name"
                        body={renderOrganization}  
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        body={selectOrganization}  
                        className='p-0 pl-3'
                        headerClassName='text-sm' 
                    />
                </DataTable>
            </div>
        </div>
    )
}