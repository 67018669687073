import axios from "axios";
import JDOpsQAsPage from "./JDOpsQasPage";
import { useEffect, useState } from "react";
import JDManageSkills from "./JDManageSkills";
import JDGeneratePage from "./JDGeneratePage";
import { useNavigate } from 'react-router-dom';
import JDIdealCandidate from "./JDIdealCandidate";
import logo from './../../../assets/icons/logo.svg';
import JDService from "../../../services/jd.service";
import { MdKeyboardArrowLeft } from "react-icons/md";
import JDTechnicalQAsPage from './JDTechnicalQAsPage';
import { TabView, TabPanel } from 'primereact/tabview';
import { extractJDFilePath } from "../../../utils/common";
import CommonService from "../../../services/common.service";
import { useAuth } from "../../../contextProviders/AuthContext";
import { useToast } from "../../../contextProviders/ToastContext";
import JDInitialScreeingQAsPage from './JDInitialScreeningQAsPage';
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import { useConfig } from "../../../contextProviders/ConfigContext";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import { EMPLOYMENT_TYPES } from "../../../utils/constants";
import JDVideoProfilingPage from "./JDVideoProfilingPage";

export default function JDCreatePage(){
    const {user} = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const { clearJD, getJDInfo } = useJDInfo();
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const jdInfo = getJDInfo();
    const [uploading, setUploading] = useState(false);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    useEffect(() => {
        clearJD();
    }, []);

    const goBack = () => navigate(-1)

    const onFinalizedJD = () => {
        setShowConfirmationDialog(true);
    }

    const submitFinalJD = async () => {
        try {
            setUploading(true);
            const requestData = {
                title: jdInfo?.title,
                duration: jdInfo?.duration,
                detectAnomaly: jdInfo?.detectAnomaly,
                primarySkills: jdInfo?.primarySkills,
                secondarySkills: jdInfo?.secondarySkills,
                startWithSummary: jdInfo?.startWithSummary,
                bpoOpsAnswerType: jdInfo?.bpoOpsAnswerType?.code,
                idealCandidateText: jdInfo?.idealCandidateText,
                opsScreeningQAs: jdInfo?.["opsScreeningQAs"] || [],
                evaluationThreshold: jdInfo?.['evaluationThreshold'],
                requireCodingQuestions: jdInfo?.requireCodingQuestions,
                initialScreeningQAs: jdInfo?.["initialScreeningQAs"] || [],
                videoProfilingQAs: jdInfo?.["videoProfilingQAs"] || [],
                codingQuestions: jdInfo?.["codingQuestions"] || [],
                interviewFocusOn: jdInfo?.interviewFocusOn?.code,
                difficultyLevel: jdInfo?.difficultyLevel?.code?.toLowerCase(),
                technicalScreeningQAs: jdInfo?.["technicalScreeningQAs"] || [],
                isCheckForInterpersonalSkills: jdInfo['isCheckForInterpersonalSkills'],
            };

            if(EMPLOYMENT_TYPES.some(employmentType => employmentType.code === jdInfo?.employmentType?.code))
                requestData['employmentType'] = jdInfo?.employmentType?.code;
            if(jdInfo?.location && jdInfo?.location?.trim()?.length > 0)
                requestData['location'] = jdInfo?.location?.includes(",") ?jdInfo?.location?.split(",") : [jdInfo?.location]

            if(jdInfo?.experience)
                requestData['experience'] = `${jdInfo?.experience[0]}-${jdInfo?.experience[1]} years`;

            if(jdInfo?.idealCandidateText)
                requestData['idealCandidateText'] = jdInfo?.idealCandidateText;
            if(jdInfo?.idealCandidateAudioUrl)
                requestData['idealCandidateAudioUrl'] = jdInfo?.idealCandidateAudioUrl;

            if(jdInfo?.selectedOption === "UPLOAD" && jdInfo?.uploadedJDFile){
                const fileName = jdInfo?.uploadedJDFile.name.replace(/&/g, '-').replace(/ /g, '-');

                const presignUrl = await CommonService.generatePresignUrlForJD(fileName);
                const {url} = presignUrl.data.data;
                
                await axios.put(url, jdInfo?.uploadedJDFile, { headers: { 'x-ms-blob-type': 'BlockBlob'} });
                requestData['originalJdUrl'] = extractJDFilePath(url, '-');
            }

            const fileName = new Date().getTime() + '.pdf';
            const presignUrl = await CommonService.generatePresignUrlForJD(fileName);
            const {url} = presignUrl.data.data;

            const response = await CommonService.generatePdfFromHtml(jdInfo?.jdHtml);
            const pdfData = response.data.data;

            if (!pdfData || Object.keys(pdfData).length === 0) {
                console.error('PDF data is empty.');
            } else {
                // Convert the object to Uint8Array
                const pdfArray = new Uint8Array(typeof pdfData === "object" ? Object.values(pdfData) : pdfData);
                // console.log('PDF array length:', pdfArray.length);
        
                await axios.put(url, pdfArray, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': 'application/pdf',
                        'Content-Length': pdfArray.length
                    }
                });
            }
            requestData['jdUrl'] = extractJDFilePath(url, '-');

            const jdResponse = await JDService.createJD(requestData);
            if(jdResponse.status === 201){
                clearJD();
                showSuccessMessage({summary: 'Created', detail: "JD is successfully uploaded"})
                navigate('/dashboard/job-description')
            } 
        }
        catch (error) {
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
        finally{
            setUploading(false);
        }
    }

    function renderTabView() {

            // Function to go to the next tab
        const goToNextTab = () => {
            const nextTabIndex = (activeTabIndex + 1) % totalTabs;
            setActiveTabIndex(nextTabIndex);
        };

        // Function to go to the previous tab
        const goToPreviousTab = () => {
            const previousTabIndex = (activeTabIndex - 1 + totalTabs) % totalTabs;
            setActiveTabIndex(previousTabIndex);
        };

        const tabsConfig = [
            {
                key: "jobDescription",
                header: "Job Description",
                component: (
                    <JDGeneratePage 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                    />
                ),
                condition: true,
                index: 0,
            },
            {
                key: "manageSkills",
                header: "Manage Skills",
                component: (
                    <JDManageSkills 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                        goToPreviousTab={goToPreviousTab}
                    />
                ),
                condition: config?.['withWeightage'],
                index: 1,
            },
            {
                key: "videoProfiling",
                header: "Video Profiling",
                component: (
                    <JDVideoProfilingPage 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                        goToPreviousTab={goToPreviousTab}
                    />
                ),
                condition: config?.enabledServices?.videoProfiling,
                index: 2,
            },
            {
                key: "hrScreening",
                header: "HR Initial Screening",
                component: (
                    <JDInitialScreeingQAsPage 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                        goToPreviousTab={goToPreviousTab}
                    />
                ),
                condition: config?.enabledServices?.hrScreening || config?.enabledServices?.bpoHrScreening,
                index: 3,
            },
            {
                key: "technicalScreening",
                header: "Technical Screening",
                component: (
                    <JDTechnicalQAsPage 
                        onFinalizedJD={onFinalizedJD} 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                        goToPreviousTab={goToPreviousTab}
                    />
                ),
                condition: !user?.email?.includes('alorica') && config?.enabledServices?.technicalScreening,
                index: 4,
            },
            {
                key: "opsScreening",
                header: "Ops Screening",
                component: (
                    <JDOpsQAsPage 
                        onFinalizedJD={onFinalizedJD} 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                        goToPreviousTab={goToPreviousTab}
                    />
                ),
                condition: user?.email?.includes('alorica') && config?.enabledServices?.opsScreening,
                index: 5,
            },
            {
                key: "idealCandidate",
                header: "Ideal Candidate",
                component: (
                    <JDIdealCandidate 
                        onFinalizedJD={onFinalizedJD} 
                        setActiveTabIndex={setActiveTabIndex}
                        goToNextTab={goToNextTab}
                        goToPreviousTab={goToPreviousTab}
                    />
                ),
                condition: true,
                index: 6,
            }
        ];
    
        // Filter tabs based on condition
        const filteredTabs = tabsConfig.filter(tab => tab.condition);
    
        // Get total tab count
        const totalTabs = filteredTabs.length;
    
        return (
            <div>
                <TabView
                    className="text-sm"
                    activeIndex={activeTabIndex}
                    pt={{ panelContainer: { className: 'mt-6' }}}
                    onTabChange={({ index }) => setActiveTabIndex(index)}
                >
                    {filteredTabs.map((tab, idx) => (
                        <TabPanel
                            key={tab.key}
                            header={tab.header}
                            disabled={activeTabIndex !== idx}
                            headerClassName="text-[#999999] font-normal"
                        >
                            {tab.component}
                        </TabPanel>
                    ))}
                </TabView>
            </div>
        );
    }    

    return (
        <div className="rounded flex flex-col overflow-y-hidden h-screen pt-2 p-5 pb-0">
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <p className="text-brownGray text-base">Would you like to finalize the Job Description?</p>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={submitFinalJD}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${!showConfirmationDialog && uploading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">{uploading && <LoadingComponent/>}</div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-[#161616] cursor-pointer h-12">
                    <MdKeyboardArrowLeft onClick={goBack} size={26} />
                    <h1 className='text-xl text-primary font-bold'>Generate Job Description</h1>
                </div>
            </div>
            <div className="overflow-scroll pb-10 px-5">
              {renderTabView()}
            </div>
        </div>
    )
}