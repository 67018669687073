import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { CgTranscript } from "react-icons/cg";
import { Rating } from '@smastrom/react-rating';
import { BounceLoader } from 'react-spinners';
import { RESUME_STATUS } from '../../utils/constants';
import ReportService from '../../services/report.service';
import { useToast } from '../../contextProviders/ToastContext';
import { useAuth } from '../../contextProviders/AuthContext';
import CircularProgress from '../../components/Report/CircularProgress';
import defaultImage from './../../assets/images/default-interview-image.png';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { capitalizeFirstLetterOfEachWord, convertUTCToLocal, getRating, secondsToMinutes } from '../../utils/common';
import InterviewFeedbackPopup from '../../components/Common/InterviewFeedbackPopup';

export default function ReportVideoProfiling({report}){
    const videoRef = useRef(null);
    const {user} = useAuth();
    const {showErrorMessage} = useToast();
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showInterviewFeedbackPopup, setShowInterviewFeedbackPopup] = useState(false);
    const videoProfiling = (report && report?.video_profiling) ? report?.video_profiling[0] : report ? report : {};

    const [hoveredSkillColor, setHoveredSkillColor] = useState(null);

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);
    const audioAnalysis = videoProfiling?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_00')[0];


    const mandatorySkills = videoProfiling && videoProfiling?.['mandatory_skills'] ? videoProfiling?.['mandatory_skills'] : [];
    const optionalSkills = videoProfiling && videoProfiling?.['optional_skills'] ? videoProfiling?.['optional_skills'] : [];
    const skillRelevance = [...mandatorySkills , ...optionalSkills ];

    const showAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(true);
    const hideAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(false);

    if(!videoProfiling || Object.keys(videoProfiling).length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    report?.status['videoProfiling'] === RESUME_STATUS.COMPLETED ? 'Report is being prepared, will be available shortly.' : 'Report not initiated'} 
                </h1>
            </div>
        )
    }

    const getDurationInSeconds = () => {
        if(videoProfiling?.['start_time'] && videoProfiling?.['end_time']){
            const startTime = new Date(videoProfiling?.['start_time']);
            const endTime = new Date(videoProfiling?.['end_time']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        else if(videoProfiling?.['startTime'] && videoProfiling?.['endTime']){
            const startTime = new Date(videoProfiling?.['startTime']);
            const endTime = new Date(videoProfiling?.['endTime']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        return 'NA';
    }

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getReport(report['id'] || videoProfiling['id']);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
            setLoading(false);
        }
    }

    const renderSkills = (skills) => {
        if (!skills || skills.length === 0) return null;
    
        return (
            <div className='flex flex-col gap-3'>
                <ul className="flex gap-4 flex-wrap">
                    {skills.map((item, index) => {
                        const { name, score, weightage } = item;
                        const percentage = weightage > 0 ? ((score / weightage) * 100).toFixed(2) : 0;
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                onMouseEnter={() => {
                                    setHoveredSkill(item);
                                    setHoveredSkillColor(colorClass);
                                }}
                                onMouseLeave={() => {
                                    setHoveredSkill(null);
                                    setHoveredSkillColor(null);
                                }}
                                className={`flex gap-2 rounded-full cursor-pointer px-2 py-1 font-medium text-xs ${colorClass}`}
                            >
                                <h5>{name}</h5>
                                <span>{percentage}%</span>
                            </li>
                        );
                    })}
                </ul>
                {hoveredSkill && (
                    <div className={`text-sm font-normal w-2/3 px-4 py-2 rounded-full ${hoveredSkillColor} transition-opacity duration-1000 ${
                        hoveredSkill ? 'opacity-100' : 'opacity-0'
                    }`}>
                        <p>{hoveredSkill['summary']}</p>
                    </div>
                )}
            </div>
        );
    };

    const renderExperienceSkills = (skills = []) => {
        if (!skills.length) return null;
    
        return (
            <div className='flex flex-col gap-5 mt-4'>
                <div className='flex'>
                    <div className='flex-1'>
                        <h5 className='font-semibold text-brownGray'>Skill</h5>
                    </div>
                    <div className='flex-1'>   
                        <h5 className='font-semibold text-brownGray'>Required Experience</h5>       
                    </div>
                    <div className='flex-1'>
                        <h5 className='font-semibold text-brownGray'>Candidate Experience</h5>   
                    </div>
                </div>
                <ul className="flex gap-4 flex-col flex-wrap">
                    {skills.map((item, index) => {
                        const { name, required_experience, candidate_experience } = item;
                        const percentage = 
                        required_experience === 0 && candidate_experience >= 0 ? 100 :
                        required_experience ? Math.min((candidate_experience / required_experience) * 100, 100).toFixed(2) : 
                            0;
        
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                className='flex relative rounded-full font-medium text-xs'
                            >
                                <div className='flex-1'>
                                    <h5 className='font-semibold text-primary'>{name}</h5>
                                </div>
                                <div className='flex-1'>   
                                    <span className={`${colorClass} rounded-md px-4 py-1`}>{required_experience} years </span>         
                                </div>
                                <div className='flex-1'>
                                    <span className={`${colorClass} rounded-md px-4 py-1`}>{candidate_experience} years</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };  

    return (
        <div className='flex w-full flex-col pt-5 gap-10'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            {showTranscript && (
                <TranscriptComponent 
                    id={videoProfiling?.id || videoProfiling?._id} 
                    hide={() => setShowTranscript(false)}
                />
                )
            }
            {showInterviewFeedbackPopup && (
                <InterviewFeedbackPopup 
                    sessionId={videoProfiling?.id || videoProfiling?._id} 
                    hide={() => setShowInterviewFeedbackPopup(false)}
                />
            )}
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    {videoProfiling?.['video_path'] && (
                        <ReactPlayer
                            ref={videoRef}
                            controls
                            pip={false}
                            playing={isPlaying}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            className="react-player flex-1"
                            url={videoProfiling?.video_path}
                        />
                    )}
                    {!videoProfiling?.['video_path'] && (
                        <div  className="react-player flex-1 flex-col-reverse flex gap-4 relative">
                            <img 
                                alt='default'
                                src={defaultImage}
                                className='h-full rounded-md'
                            />
                            <div className='absolute bottom-20 z-50 px-3 text-center flex justify-center items-center w-full'>
                                <p className='text-white font-bold text-center text-base'>Video of the interview is not available</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col gap-4 flex-1 items-start'>
                        <div className='flex justify-center items-center gap-10 pl-20'>
                            <CircularProgress score={parseFloat(videoProfiling?.score || videoProfiling?.total_score).toFixed(1)}/>
                        </div>
                        <div className={`flex items-center gap-2 text-primary mt-5 ${videoProfiling?.start_time ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Date of the interview :</h3>
                            <span className=''>{videoProfiling?.start_time ? convertUTCToLocal(videoProfiling?.start_time) : 'Not captured' }</span>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${videoProfiling['is_interested'] ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Is interested in role:</h3>
                            <h4 className=''>{videoProfiling['is_interested'] ? 'Yes' : 'No'}</h4>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Interview Duration:</h3>
                            <span className=''>{secondsToMinutes(getDurationInSeconds())}</span>
                        </div>
                        {/* <div className='flex items-center gap-2'>
                            <p className='font-semibold text-primary'>Transcript of the interview</p>
                            <CgTranscript 
                                size={28}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div>
                        <button
                            disabled={loading}
                            onClick={extractReport} 
                            className={`border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${videoProfiling?.['score']  > 0 ? 'visible': 'hidden'}`}>
                            {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                        </button> */}
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-5'>
                <button
                    onClick={() => setShowTranscript(!showTranscript)}
                    className='border text-xs h-9 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                    View Transcript
                </button>
                <button
                    disabled={loading}
                    onClick={extractReport} 
                    className={`border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${report?.total_score > 0 ? 'visible': 'hidden'}`}>
                    {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                </button>
                <button
                    onClick={() => setShowInterviewFeedbackPopup(true)} 
                    className={`border h-9 px-6 text-xs flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${!videoProfiling['report_feedback'] ? 'visible': 'hidden'}`}>
                    Next round status
                </button>
            </div>
            <div className={`flex flex-col gap-3 ${videoProfiling?.summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Overall Summary</h4>
                <CustomReactMarkdown
                    data={videoProfiling?.summary}
                />
            </div>
            <div className={`flex flex-col gap-3 ${videoProfiling?.conversation_summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Conversation Summary</h4>
                <CustomReactMarkdown
                    data={videoProfiling?.conversation_summary}
                />
            </div>	
            <div className={`flex flex-col gap-3 ${videoProfiling?.relevance ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Overall Relevance</h4>
                <CustomReactMarkdown data={videoProfiling?.relevance} />
            </div>
            <div className={`flex gap-2 flex-col ${videoProfiling?.skill_relevance ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Skill relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(videoProfiling?.skill_relevance?.score || 0)?.value} 
                        />
                    </div>
                    <p className='text-primary'>{videoProfiling?.skill_relevance?.summary}</p>
                    {renderSkills(skillRelevance)}
            </div>
            <div className={`flex gap-2 flex-col ${videoProfiling?.experience_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Experience Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(videoProfiling?.experience_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{videoProfiling?.experience_relevance?.summary}</p>
                {renderExperienceSkills(skillRelevance)}
            </div>
            <div className={`flex gap-2 flex-col ${videoProfiling?.work_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Work Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(videoProfiling?.work_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{videoProfiling?.work_relevance?.summary}</p>
            </div>
            {/* <div className={`flex gap-2 flex-col ${videoProfiling?.industry_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Industry Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(videoProfiling?.industry_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{videoProfiling?.industry_relevance?.summary}</p>
            </div>
            <div className={`flex gap-2 flex-col ${videoProfiling?.company_relevance ? 'visible' : 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Company Relevance</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={getRating(videoProfiling?.company_relevance?.score || 0).value} 
                    />
                </div>
                <p className='text-primary'>{videoProfiling?.company_relevance?.summary}</p>
            </div> */}
            <div className={`flex flex-col gap-5 ${videoProfiling?.roles_and_responsibilities?.length > 0 ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Roles And Responsibilities</h4>
                <ul className='flex gap-5 flex-wrap'>
                    {videoProfiling?.roles_and_responsibilities?.map((item, index) => (
                        <li key={index?.toString()} className='bg-lightBlue p-3 rounded-lg text-primary'>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`flex flex-col ${videoProfiling && videoProfiling?.['strengths'] && videoProfiling?.['strengths']?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Strengths</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {videoProfiling?.['strengths'] && videoProfiling?.['strengths'].map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-sm font-bold'>{capitalizeFirstLetterOfEachWord(item['strength'])}</h5>
                                    <p className='text-sm font-normal'>{item['description']}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${videoProfiling?.['areas_of_improvement'] && videoProfiling?.['areas_of_improvement']?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Areas of improvement</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {videoProfiling?.['areas_of_improvement'] && videoProfiling?.['areas_of_improvement'].map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-sm font-bold'> {capitalizeFirstLetterOfEachWord(item['area'])}</h5>
                                    <p className='text-sm font-normal'>{item['description']}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${videoProfiling?.['responses'] && Object.keys(videoProfiling['responses'])?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Responses</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {videoProfiling?.['responses'] && Object.keys(videoProfiling['responses']).map((item, index) => {
                            return (
                                <li 
                                    className='w-[45%]'
                                    key={index?.toString()}>
                                    <div className='flex justify-between gap-2 flex-col'>
                                        <h5 className='text-sm font-bold'> {capitalizeFirstLetterOfEachWord(item)?.replaceAll('_', ' ')}</h5>
                                        <p className='text-sm font-normal'>{videoProfiling['responses'][item]['response']}</p>
                                        <div className='mt-2 flex flex-col gap-2'>
                                            <h4 className='text-sm font-bold'>Analysis</h4>
                                            <p className='text-xs font-semibold text-dg'>{videoProfiling['responses'][item]['analysis']['positive']}</p>
                                            <p className='text-xs font-semibold text-dr'>{videoProfiling['responses'][item]['analysis']['negative']}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex gap-5 items-center'>
                        <CircularProgress
                            size={60}
                            strokeWidth={7} 
                            score={parseFloat(audioAnalysis?.score).toFixed(1)}
                        />
                        <div className={`flex items-center flex-col gap-2 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRating(audioAnalysis?.score, user?.['organization']).value} 
                            />
                            <span className='font-semibold text-xs'>{getRating(audioAnalysis?.score, user?.['organization']).value} out of 5 stars</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${videoProfiling['report_feedback'] ? 'visible': 'hidden'}`}>
                <div className='flex gap-4 items-center'>
                    <h1 className='text-xl font-bold'>Recruiter feedback on next round</h1>
                    <div className={`text-sm h-7 px-3 rounded-full flex justify-center items-center font-semibold ${videoProfiling['report_feedback']?.status === 'SELECTED' ? 'bg-dg': videoProfiling['report_feedback']?.status === 'REJECTED' ? 'bg-dr': 'bg-do'}`}>
                        <span className={`text-xs ${videoProfiling['report_feedback']?.status === 'SELECTED' ? 'text-lgg': videoProfiling['report_feedback']?.status === 'REJECTED' ? 'text-lr': 'text-lo'}`}>{videoProfiling['report_feedback']?.status}</span>
                    </div>
                </div>
                {videoProfiling['report_feedback']?.feedback && <p className='text-primary'>{videoProfiling['report_feedback']?.feedback}</p>}
            </div>
            <div className={`flex flex-col gap-3 ${videoProfiling['candidate_feedback'] && videoProfiling['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={videoProfiling['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{videoProfiling['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}