import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";
import logo from './../../../assets/icons/logo.svg';
import JDService from "../../../services/jd.service";
import { MdKeyboardArrowLeft } from "react-icons/md";
import JDManageEditSkills from "./JDManageEditSkills";
import { TabView, TabPanel } from 'primereact/tabview';
import JDOpsQAsEditSection from "./JDOpsQAsEditSection";
import ConsoleHelper from "../../../utils/consoleHelper";
import { useNavigate, useParams } from 'react-router-dom';
import JDGenerateEditSection from "./JDGenerateEditSection";
import CommonService from "../../../services/common.service";
import { useAuth } from "../../../contextProviders/AuthContext";
import { useToast } from "../../../contextProviders/ToastContext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import JDTechnicalQAsEditSection from "./JDTechnicalQAsEditSection";
import { useConfig } from "../../../contextProviders/ConfigContext";
import JDIdealCandidateEditSection from "./JDIdealCandidateEditSection";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import { extractIdealCandidateAudiosFilePath } from "../../../utils/common";
import JDInitialScreeingQAsEditSection from "./JDInitialScreeingQAsEditSection";
import JDVideoProfilingEditPage from "./JDVideoProfilingEditPage";

export default function JDEditPage(){
    const {user} = useAuth();
    const {id} = useParams();
    const uniqueId = uuidv4();
    const config = useConfig();
    const navigate = useNavigate();
    const { clearJD } = useJDInfo();
    const goBack = () => navigate(-1)
    const [loading, setLoading] = useState(false);
    const {showWarningMessage} = useToast();

    const { getJDInfo, changeJDInfo } = useJDInfo();
    const jdInfo = getJDInfo();

    const [uploading, setUploading] = useState(false);
    const [initialJDInfo, setInitialJDInfo] = useState({});
    
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const onFinalizedJD = () => setShowConfirmationDialog(true);

    const getTotalWeightage = () => {
        return [...jdInfo['primarySkills'], ...jdInfo['secondarySkills']].reduce((obj1, obj2) => obj1 + parseInt(obj2.weightage), 0);
    }

    const hasChanged = () => {
        if(JSON.stringify(initialJDInfo['primarySkills']) !== JSON.stringify(jdInfo['primarySkills']))
            return true;
        else if(JSON.stringify(initialJDInfo['secondarySkills']) !== JSON.stringify(jdInfo['secondarySkills']))
            return true;

        return Object.keys(getChangedFields(initialJDInfo, jdInfo))?.length ? true : false
    }

    useEffect((() => {
        async function init(){
            try {
                setLoading(true)
                const response = await JDService.getJd(id);
                setLoading(false)
                if(response.status === 200){
                    const jdData = response?.data?.data || {};
                    jdData['userDetails'] = undefined;
                    jdData['resumeCount'] = undefined;
                    changeJDInfo(jdData);
                    setInitialJDInfo(jdData);
                }
            } catch (error) {
                setLoading(false)
                ConsoleHelper.error(JSON.stringify(error))
            }
        }

        init();
    }), [])

    function getChangedFields(original, updated) {
        const changes = {};
        original = {
            ...original, 
            videoProfilingQAs: original?.videoProfilingQAs?.filter((item) => item?.question?.trim()?.length > 0 ),
            initialScreeningQAs: original.initialScreeningQAs?.filter((item) => item?.question?.trim()?.length > 0 ),
            technicalScreeningQAs: original?.technicalScreeningQAs?.filter((item) => item?.question?.trim()?.length > 0 ),
        }
        
        updated = {
            ...updated, 
            videoProfilingQAs: updated?.videoProfilingQAs?.filter((item) => item?.question?.trim()?.length > 0 ),
            initialScreeningQAs: updated.initialScreeningQAs?.filter((item) => item?.question?.trim()?.length > 0 ),
            technicalScreeningQAs: updated?.technicalScreeningQAs?.filter((item) => item?.question?.trim()?.length > 0 ),
        }
    
        Object.keys(updated).forEach(key => {
            const originalValue = original[key];
            const updatedValue = updated[key];
    
            // Check for array type
            if (Array.isArray(updatedValue)) {
                if (JSON.stringify(originalValue) !== JSON.stringify(updatedValue)) {
                    changes[key] = updatedValue;
                }
            }
            // Check for object type
            else if (typeof updatedValue === 'object' && updatedValue !== null) {
                if (JSON.stringify(originalValue) !== JSON.stringify(updatedValue)) {
                    changes[key] = updatedValue;
                }
            }
            // Check for primitive types
            else {
                if (originalValue !== updatedValue) {
                    changes[key] = updatedValue;
                }
            }
        });

        delete changes['sortFieldNormalized'];
        return changes;
    }


    const submitFinalJD = async () => {
        try {
            const values = getChangedFields(initialJDInfo, jdInfo);
            if(Object.keys(values)?.length === 0){
                showErrorMessage({ summary: 'Fields not changed',  detail: 'No fields are changed'})
                return;
            }

            const isSkillsChanged = 
                JSON.stringify(initialJDInfo['primarySkills']) !== JSON.stringify(jdInfo['primarySkills']) || 
                JSON.stringify(initialJDInfo['secondarySkills']) !== JSON.stringify(jdInfo['secondarySkills']);

            const mandatorySkills = jdInfo['primarySkills']?.map((item) => item?.name || item);
            const optionalSkills =jdInfo['secondarySkills']?.map((item) => item?.name || item);

            // if(isSkillsChanged){
            //     if(mandatorySkills?.length > 4 || optionalSkills?.length > 4){
            //         showErrorMessage({
            //             life: 3000,
            //             summary: 'Limit', 
            //             detail: 'You can add upto 4 mandatory or optional skills'
            //         });
            //         return;
            //     }    
            // }


            setUploading(true);
            let requestData = values;

            if(isSkillsChanged){
                const data = {
                    jd: '',
                    primarySkills: mandatorySkills,
                    secondarySkills: optionalSkills, 
                };

                const response = await CommonService.evaluateJdSkills(data);
                if(response.status === 200){
                    const responseData = response.data['data'];
                    if(requestData?.["primarySkills"] && requestData["primarySkills"]?.length){
                        const skills = responseData['primarySkills'];
                        requestData['primarySkills'] = skills;
                    }
                    if(requestData?.["secondarySkills"] && requestData["secondarySkills"]?.length){
                        const skills = responseData['secondarySkills'];
                        requestData['secondarySkills'] = skills;
                    }
                }
            }

            if(requestData.blob){
                const fileName = `${uniqueId}-${Date.now()}.mp3`;
    
                const presignUrl = await CommonService.generatePresignUrlForIdealCandidateAudio(fileName);
                const {url} = presignUrl.data.data;
                await axios.put(url, requestData.blob, { headers: { 'x-ms-blob-type': 'BlockBlob'} });

                requestData['idealCandidateAudioUrl'] = extractIdealCandidateAudiosFilePath(url, '-');
                delete requestData.blob;
            }

            if(values?.uploadedJDFile || values?.jdHtml){
                let fileName = null;

                if(values?.uploadedJDFile) 
                    fileName = values?.uploadedJDFile.name.replace(/&/g, '-').replace(/ /g, '-');
                else fileName = new Date().getTime() + '.pdf';
    
                const presignUrl = await CommonService.generatePresignUrlForJD(fileName);
                const {url} = presignUrl.data.data;

                if(values?.selectedOption === "UPLOAD")
                    await axios.put(url, values?.uploadedJDFile, { headers: { 'x-ms-blob-type': 'BlockBlob'}});
                else {
                    const response = await CommonService.generatePdfFromHtml(values?.jdHtml);
                    const pdfBuffer = response.data.data;
                    const pdfArray = new Uint8Array(pdfBuffer.data);
                    await axios.put(url, pdfArray, {
                        headers: {
                            'x-ms-blob-type': 'BlockBlob',
                            'Content-Type': 'application/pdf',
                            'Content-Length': pdfArray.length
                        }
                    });
                }
            }

            if(values?.location)
                requestData['location'] = requestData['location']?.split(",");
            if(values?.experience)
                requestData['experience'] = `${values?.experience[0]}-${values?.experience[1]} years`;
            if(values?.bpoOpsAnswerType)
                requestData['bpoOpsAnswerType'] = requestData['bpoOpsAnswerType']?.code;
            if(values?.difficultyLevel)
                requestData['difficultyLevel'] = requestData['difficultyLevel']?.code?.toLowerCase();
            if(values?.interviewFocusOn)
                requestData['interviewFocusOn'] = requestData['interviewFocusOn']?.code;

            const jdResponse = await JDService.updateJD(id, requestData);
            setUploading(false);
            if(jdResponse.status === 200){
                clearJD();
                showSuccessMessage({summary: 'Updated', detail: "JD is successfully updated"})
                navigate('/dashboard/job-description')
            } 
        }
        catch (error) {
            setUploading(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }

    const onUpdateButtonClick = async () => {
        if (jdInfo['requireCodingQuestions']) {
            if (jdInfo['duration'] < 40)
                changeJDInfo({...jdInfo, duration: 40});
        
            const questionsAnswers = jdInfo?.['codingQuestions'];
            if (
                !questionsAnswers || 
                questionsAnswers.length === 0 || 
                (questionsAnswers.length === 1 && !questionsAnswers[0].question?.trim())
            ) {
                showWarningMessage({
                    life: 5000,
                    summary: 'Coding Question Requirement',
                    detail: 'Please ensure that at least one coding question is included, designed to be solvable by the candidate within 10–12 minutes during the interview.'
                });
                return;
            }
        }

        onFinalizedJD();
    }

    function renderEditTabView() {
        const tabsConfig = [
            {
                key: "jobDescription",
                header: "Job Description",
                component: <JDGenerateEditSection />,
                condition: true,
            },
            {
                key: "manageSkills",
                header: "Manage Skills",
                component: <JDManageEditSkills />,
                condition: config?.['withWeightage'],
            },
            {
                key: "videoProfiling",
                header: "Video Profiling",
                component: <JDVideoProfilingEditPage />,
                condition: config?.enabledServices?.videoProfiling,
            },
            {
                key: "hrScreening",
                header: "HR Initial Screening",
                component: <JDInitialScreeingQAsEditSection />,
                condition: config?.enabledServices?.hrScreening || config?.enabledServices?.bpoHrScreening,
            },
            {
                key: "technicalScreening",
                header: "Technical Screening",
                component: <JDTechnicalQAsEditSection />,
                condition: !user?.email?.includes('alorica') && config?.enabledServices?.technicalScreening,
            },
            {
                key: "opsScreening",
                header: "Ops Screening",
                component: <JDOpsQAsEditSection />,
                condition: user?.email?.includes('alorica') && config?.enabledServices?.opsScreening,
            },
            {
                key: "idealCandidate",
                header: "Ideal Candidate",
                component: <JDIdealCandidateEditSection />,
                condition: true,
            }
        ];
    
        // Filter tabs based on condition
        const filteredTabs = tabsConfig.filter(tab => tab.condition);
    
        return (
            <TabView
                className="text-sm"
                pt={{ panelContainer: { className: 'mt-6' }}}
            >
                {filteredTabs.map((tab) => (
                    <TabPanel
                        key={tab.key}
                        header={tab.header}
                        headerClassName="text-[#999999] font-normal"
                    >
                        {tab.component}
                    </TabPanel>
                ))}
            </TabView>
        );
    }    

    if(loading){
        return (
            <div className="h-full flex justify-center items-center overflow-y-hidden p-5 pt-0 pb-0">
                {loading && <LoadingComponent/>}
            </div>
        )
    }

    return (
        <div className="rounded flex flex-col overflow-y-hidden h-screen p-5 pt-0 pb-0">
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <p className="text-brownGray text-base">Would you like to update the Job Description?</p>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={submitFinalJD}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${!showConfirmationDialog && uploading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">{uploading && <LoadingComponent/>}</div>
            </div>
            <div className="flex flex-row items-center justify-between w-full py-3">
                <div className="flex items-center gap-1 text-primary cursor-pointer">
                    <MdKeyboardArrowLeft onClick={goBack} size={26} />
                    <h1 className='text-xl text-primary font-bold'>Edit Job Description</h1>
                </div>
                <button 
                    onClick={onUpdateButtonClick}
                    disabled={hasChanged() ? false : true}
                    className={`bg-blue px-10 h-10 text-white rounded-md text-sm ${hasChanged() ? 'opacity-100': 'opacity-40'}`}>Update</button>
            </div>
            <div className="overflow-scroll pb-10 px-5">
                {renderEditTabView()}
            </div>
        </div>
    )
}