import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import logo from './../../../assets/icons/logo.svg';
import JDService from '../../../services/jd.service';
import addIcon from './../../../assets/icons/add.svg';
import ConsoleHelper from '../../../utils/consoleHelper';
import { useToast } from '../../../contextProviders/ToastContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import { getPaginationLimitForJD} from '../../../utils/common';
import OrganizationService from '../../../services/organization.service';


export default function OrganisationManagement(){
    const navigate = useNavigate();
    const [first, setFirst] = useState(0);

    const [organisations, setOrganisations] = useState([]);
    
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [uploading, setUploading] = useState(false);
    const [selectedJD, setSelectedJD] = useState(null);
    
    const [clickedOn, setClickedOn] = useState(null);
    const { showErrorMessage, showSuccessMessage} = useToast();

    const [rows, setRows] = useState(getPaginationLimitForJD());
    const [limit, setLimit] = useState(getPaginationLimitForJD());

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    useEffect(() => {
        // clearJD();
        
        const handleResize = () => {
            setLimit(getPaginationLimitForJD())
            setRows(getPaginationLimitForJD())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        async function getOrganizations(){
            try {
                setLoading(true);
                const response = await OrganizationService.getOrganizations(currentPage, limit);
                setLoading(false);
                if(response.status === 200){
                    const {data, totalCount, totalPage} = response.data;
                    setOrganisations(data)
                    setTotalCount(totalCount);
                    setTotalPage(totalPage);
                }
            } catch (error) {
                setLoading(false);
                ConsoleHelper.error(error?.response?.data?.message)
            }
        }
        getOrganizations();
    }, [currentPage, limit]);

    const onRowClick = ({data}) => {
        navigate(`/dashboard/organisations-management/${data._id}`, {
            state: data
        })
    }

    const toggleJDActiveStatus = async () => {
        try {
            setUploading(true);
            const jdResponse = 
                clickedOn === "activate" ? await JDService.activateJD(selectedJD?.id) : await JDService.inactivateJD(selectedJD?.id);
            setUploading(false);
            if(jdResponse.status === 200){
                setShowConfirmationDialog(false);
                setOrganisations(organisations?.map((item => {
                    if(item?._id === selectedJD?.id)
                        return {...item, active: clickedOn === "activate" ? true : false}
                    return item;
                })))
                setSelectedJD(null);
                if(clickedOn === "inactivate"){
                    showSuccessMessage({
                        life: 5000,
                        summary: 'Alert', 
                        detail: "Please ensure there are no active interview invites. Candidate can continue the interview if any invites are sent"
                    })
                }
                else {
                    showSuccessMessage({
                        summary: 'Success', 
                        detail: "JD is activated"
                    })
                }
            } 
        }
        catch (error) {
            setUploading(false);
            setShowConfirmationDialog(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }

    return (
        <div 
            onClick={() => setSelectedJD(null)}
            className="flex flex-col h-full gap-4 p-5">
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <div className='flex flex-col items-center justify-center'>
                            <p className="text-brownGray text-base">Would you want to {clickedOn === "activate"? "activate" : "inactivate"} the JD?</p>
                        </div>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={toggleJDActiveStatus}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <h1 className='text-[#161616] text-xl font-bold'>Organisation list</h1>
                <div 
                    onClick={() => navigate('/dashboard/organisations-management/create')}
                    className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4">
                    <span>Create Organisation</span>
                    <img 
                        alt='add'
                        src={addIcon}
                        className='h-5 w-5'
                    />
                </div>
            </div>
            {/* <div className='flex gap-4'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <ExperienceDropdownComponent 
                    label={selectedExperience}
                    setLabel={setSelectedExperience}
                />
                <LocationDropdownComponent 
                    label={selectedLocation}
                    setLabel={setSelectedLocation}
                />
            </div> */}
            <div 
                className="bg-white rounded mt-5">
                <DataTable 
                    value={organisations}
                    loading={loading}
                    emptyMessage="No Organisations available"
                    onRowClick={onRowClick}
                    rowClassName='bg-white hover:text-blue cursor-pointer'>
                    <Column 
                        header="" 
                        headerClassName='w-24'
                        body={({logo}) => (
                            <div>
                                <img
                                    src={logo}
                                    alt='logo'
                                    className='h-10 w-10 rounded-full'
                                />
                            </div>
                        )}
                    />
                    <Column 
                        field="organizationID" 
                        header="Id" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base w-40' 
                    />
                    <Column 
                        field="name" 
                        header="Name" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        header="Website" 
                        body={({website}) => (
                            <div className=''>
                                <span 
                                    onClick={() => window.open(website, '_blank')}
                                    className='underline line-clamp-1'>
                                    {website}
                                </span>
                            </div>
                        )}
                        className='text-sm 2xl:text-base w-1/3'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="userCount" 
                        header="Hr's" 
                        body={({userCount}) => (
                            <div className='h-8 w-8 flex items-center justify-center bg-primary rounded-full'>
                                <span className="text-white font-medium">{userCount}</span>
                            </div>
                        )}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="industry" 
                        header="Industry" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    {/* <Column 
                        field="hrTeam" 
                        header="HR team" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    /> */}
                    <Column 
                        field="status" 
                        header="Status" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        field="noOfHiring" 
                        header="No. of hiring" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                    {/* <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                className={`${totalPage > 1 ? 'visible': 'hidden'} justify-end bg-[#ECF5FA]`}
            />
        </div>
    )
}