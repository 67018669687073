import axios from 'axios';
import {AiOutlineUpload} from 'react-icons/ai';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import ConsoleHelper from '../../../utils/consoleHelper';
import CommonService from '../../../services/common.service';
import PromptService from '../../../services/prompt.service';
import { GREETING_MESSAGE } from '../../../utils/constants';
import { useToast } from '../../../contextProviders/ToastContext';
import OrganizationService from '../../../services/organization.service';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import CheckboxComponent from '../../../components/Common/CheckboxComponent';
import { extractCompanyLogosFilePath, isNumberOnly, isValidUrl } from '../../../utils/common';
import OrganizationServiceOptions from '../../../components/Admin/Organization/OrganizationServiceOptions';
import OrganizationAvatarBotVoiceComponent from '../../../components/Admin/Organization/OrganizationAvatarBotVoiceComponent';

const interviewFocusOnList = [
    { name: "JD Only", code: "JD", description: `Interview questions will be focused on the JD/Role irrespective of candidate's experience`},
    { name: "Resume Only", code: "RESUME", description: `Interview questions will be based on candidate's experience and expertise irrespective of the role description`},
    { name: "JD & Resume", code: "JD_RESUME", description: `Interview questions will be around candidate's experience and expertise in context of the JD`},
    { name: "All Skills", code: "ALL_SKILLS", description: `Interview questions will cover mandatory and optional skills only irrespective of any other info in the JD and the resume`},
    { name: "Mandatory Skills Only", code: "PRIMARY_SKILLS", description: `Interview questions will cover mandatory skills only irrespective of any other info in the JD and the resume`},
]

export default function OrganisationManagementUpdate(){
    const {id} = useParams();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const fileInputRef = useRef(null);
    const [error, setError] = useState({});
    const [withWeightage, setWithWeightage] = useState(false);
    const [interviewFocusOn, setInterviewFocusOn] = useState(interviewFocusOnList[2]);

    const navigateTo = (path) => navigate(path);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [selectedBotVoice, setSelectedBotVoice] = useState(null);

    const [account, setAccount] = useState({
        email: '',
        mobile: '',
        lastName: '',
        password: '',
        firstName: '',
    })

    const [enabledServices, setEnabledServices] = useState({});
    const [initialEnabledServices, setInitialEnabledServices] = useState({});

    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [industry, setIndustry] = useState(null);
    const [initialState, setInitialState] = useState({});
    const [imagePreview, setImagePreview] = useState(null);
    const {showSuccessMessage, showErrorMessage} = useToast();
    const [organisationLogo, setOrganisationLogo] = useState(null);
    const [organisationName, setOrganisationName] = useState(null);
    const [organisationWebsite, setOrganisationWebsite] = useState(null);
    const [organisationInformation, setOrganisationInformation] = useState(null);

    const [greetingMessage, setGreetingMessage] = useState(GREETING_MESSAGE);
    const [inviteExpiryDays, setInviteExpiryDays] = useState(7)
    const [followupDelayHours, setFollowupDelayHours] = useState(24)

    const [initialPromptIds, setInitialPromptIds] = useState({
        videoProfilingAcknowledgmentGenerationPromptId: null,
        videoProfilingQuestionGenerationPromptId: null,
        hrInterviewAcknowledgmentGenerationPromptId: null,
        hrInterviewQuestionGenerationPromptId: null,
        technicalInterviewAcknowledgmentGenerationPromptId: null,
        technicalInterviewQuestionGenerationPromptId: null,
        exitInterviewAcknowledgmentGenerationPromptId: null,
        exitInterviewQuestionGenerationPromptId: null,
        bpoHRInterviewAcknowledgmentGenerationPromptId: null,
        bpoHRInterviewQuestionGenerationPromptId: null,
        bpoOpsTextInterviewAcknowledgmentGenerationPromptId: null,
        bpoOpsTextInterviewQuestionGenerationPromptId: null,
        bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId: null,
        bpoOpsVoiceInterviewQuestionGenerationPromptId: null,
    });

    const [promptIds, setPromptIds] = useState(initialPromptIds);

    const handlePromptIdChange = (key, event) => {
        const value = event?.target?.value;
        setPromptIds((prevPromptIds) => ({ ...prevPromptIds, [key]: value}));
    }

    useEffect(() => {
        async function fetchOrganizationData(){
            try {
                setLoading(true);
                const response = await OrganizationService.getOrganization(id);
                if(response.status === 200){

                    const {data} = response.data;

                    const interviewFocusOnData = 
                        interviewFocusOnList?.filter((item) => item.code === data?.['interviewFocusOn'])[0] || interviewFocusOnList[2]; 

                    setIndustry(data['industry']);
                    setImagePreview(data['logo']);
                    setOrganisationName(data['name'])
                    setSelectedAvatar(data['avatar']);
                    setSelectedBotVoice(data['voiceName']);
                    setOrganisationWebsite(data['website']);
                    setInterviewFocusOn(interviewFocusOnData);
                    setOrganisationInformation(data['information']);
                    setEnabledServices(data['enabledServices'] || {});
                    setInviteExpiryDays(data['inviteExpiryDays'] || 7);
                    setWithWeightage(data?.['withWeightage'] || false);
                    setInitialEnabledServices(data['enabledServices'] || {});
                    setFollowupDelayHours(data['followupDelayHours'] || 24);
                    setGreetingMessage(data['greetingMessage'] || GREETING_MESSAGE);

                    const users = data['users'];
                    setAccount(users[0]);

                    if(data['prompt']){
                        setPromptIds(data['prompt']);
                        setInitialPromptIds(data['prompt']);
                    }
                    
                    data['users'] = undefined;
                    setInitialState(data);

                }    
            } catch (error) {
                ConsoleHelper.log(JSON.stringify(error));
            }finally{
                setLoading(false);
            }
        }

        fetchOrganizationData();
    }, [id]);

    useEffect(() => {
        async function fetchPrompts(){
            try {
                setLoading(true);
                const response = await PromptService.getPrompts();
                setLoading(false);
                if(response.status === 200){
                    const data = response.data['data'];
                    const flattenedPrompts = Object.values(data).flat();
                    const prompts = flattenedPrompts?.map((item) => {
                        const data = {
                            _id: item['id'],
                            title: item['title'],
                            description: item['description']
                        }
                        return data;
                    })
                    setPrompts(prompts);
                }
            } catch (error) {
                setLoading(false);
            }
        }

        fetchPrompts();
    }, []);

    const isValuesChanged = () => {
        return (
            organisationLogo !== null || 
            initialState['industry'] !== industry ||
            initialState['avatar'] !== selectedAvatar || 
            initialState['name'] !== organisationName ||
            initialState['website'] !== organisationWebsite ||
            initialState['voiceName'] !== selectedBotVoice ||
            initialState['withWeightage'] !== withWeightage || 
            initialState['interviewFocusOn'] !== interviewFocusOn?.code|| 
            initialState['information'] !== organisationInformation ||
            initialState['greetingMessage'] !== greetingMessage ||
            initialState['followupDelayHours'] !== followupDelayHours || 
            initialState['inviteExpiryDays'] !== inviteExpiryDays ||
            JSON.stringify(initialPromptIds) !== JSON.stringify(promptIds) ||
            JSON.stringify(initialEnabledServices) !== JSON.stringify(enabledServices)
        )
    }

    const validate = () => {
        setError({});
        const error = {};
    
        const isEmpty = (value) => !value || value.trim().length === 0;
        const addError = (key, message) => error[key] = message;

        if (isEmpty(industry)) addError('industry', 'Industry is required');
        if (isEmpty(organisationWebsite) || !isValidUrl(organisationWebsite))
            addError('organisationWebsite', 'Valid Organisation Website is required');
        if (isEmpty(organisationInformation)) addError('organisationInformation', 'Organisation Information is required');
        if (isEmpty(organisationName)) addError('organisationName', 'Organisation Name is required');

        if(isNaN(followupDelayHours)) addError('followupDelayHours', 'Followup Delay Hours required');
        if(isNaN(inviteExpiryDays)) addError('inviteExpiryDays', 'Invite Expiry Days required');

        if(!greetingMessage || greetingMessage?.trim()?.length < 10)
            addError('greetingMessage', 'Greeting Message required');
    
        // Validate prompt IDs
        const requiredPrompts = [
            { key: 'videoProfilingQuestionGenerationPromptId', name: 'Video Profiling Question Prompt' },
            { key: 'videoProfilingAcknowledgmentGenerationPromptId', name: 'Video Profiling Acknowledgment Prompt' },
            { key: 'hrInterviewQuestionGenerationPromptId', name: 'HR Interview Question Prompt' },
            { key: 'hrInterviewAcknowledgmentGenerationPromptId', name: 'HR Interview Acknowledgment Prompt' },
            { key: 'technicalInterviewQuestionGenerationPromptId', name: 'Technical Interview Question Prompt' },
            { key: 'technicalInterviewAcknowledgmentGenerationPromptId', name: 'Technical Interview Acknowledgment Prompt' },
            { key: 'exitInterviewQuestionGenerationPromptId', name: 'Exit Interview Question Prompt' },
            { key: 'exitInterviewAcknowledgmentGenerationPromptId', name: 'Exit Interview Acknowledgment Prompt' },
            { key: 'bpoHRInterviewQuestionGenerationPromptId', name: 'BPO HR Interview Question Prompt' },
            { key: 'bpoHRInterviewAcknowledgmentGenerationPromptId', name: 'BPO HR Interview Acknowledgment Prompt' },
            { key: 'bpoOpsTextInterviewQuestionGenerationPromptId', name: 'BPO Ops Text Interview Question Prompt' },
            { key: 'bpoOpsTextInterviewAcknowledgmentGenerationPromptId', name: 'BPO Ops Text Interview Acknowledgment Prompt' },
            { key: 'bpoOpsVoiceInterviewQuestionGenerationPromptId', name: 'BPO Ops Voice Interview Question Prompt' },
            { key: 'bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId', name: 'BPO Ops Voice Interview Acknowledgment Prompt' },
        ];
    
        requiredPrompts.forEach(({ key, name }) => {
            if (!promptIds?.[key]) addError(key, `${name} is required`);
        });
    
        if (Object.keys(error).length) {
            setError(error);
            return false;
        }
        return true;
    };
    
    const handleInput = (key, event) => {
        const value = event?.target?.value;
        const inputHandlers = {
            industry: setIndustry,
            greetingMessage: setGreetingMessage,
            organisationName: setOrganisationName,
            organisationWebsite: setOrganisationWebsite,
            organisationInformation: setOrganisationInformation,
            followupDelayHours: setFollowupDelayHours,
            inviteExpiryDays: setInviteExpiryDays,
        };
    
        if (inputHandlers[key]) {
            if(key === "followupDelayHours"){
                if(value?.trim()?.length === 0 || isNumberOnly(value))
                    inputHandlers[key](value);
            }
            if(key === "inviteExpiryDays"){
                if(value?.trim()?.length === 0 || isNumberOnly(value))
                    inputHandlers[key](value);
            }
            else inputHandlers[key](value);
        }
    };

    const handleAccountInput = (key, event) => {
        const value = event?.target?.value;
        const data = {...account};
        data[key] = value;
        setAccount(data);
    }

    const handleInterviewOptions = (key, value) => {
        setEnabledServices((prev) => ({ ...prev, [key]: value }));
    };

    const handleFileChange = async event => {
        try {
            const file = event.target.files[0];
            if(!file) throw new Error("Please select file");
            setOrganisationLogo(file);
            let reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } catch (error) {
            ConsoleHelper.error(`error: ${JSON.stringify(error)}`);
        }
    };

    const submit = async (event) => {
        event.preventDefault(); 
        if(!validate()) return;

        try {
            setLoading(true);

            const requestData = {};
            const prompt = {};

            Object.keys(promptIds).forEach((key) => {
                prompt[key] = promptIds[key]?._id
            })

            if(JSON.stringify(initialPromptIds) !== JSON.stringify(promptIds))
                requestData['prompt'] = prompt;

            if(initialState['name'] !== organisationName)
                requestData['name'] = organisationName;

            if(initialState['greetingMessage'] !== greetingMessage)
                requestData['greetingMessage'] = greetingMessage;

            if(initialState['inviteExpiryDays'] !== inviteExpiryDays)
                requestData['inviteExpiryDays'] = !isNaN(parseInt(inviteExpiryDays)) ? parseInt(inviteExpiryDays) : 7;

            if(initialState['followupDelayHours'] !== followupDelayHours)
                requestData['followupDelayHours'] = !isNaN(parseInt(followupDelayHours)) ? parseInt(followupDelayHours) : 24;

            if(initialState['industry'] !== industry)
                requestData['industry'] = industry;

            if(initialState['voiceName'] !== selectedBotVoice)
                requestData['voiceName'] = selectedBotVoice;

            if(initialState['avatar'] !== selectedAvatar)
                requestData['avatar'] = selectedAvatar;

            if(initialState['website'] !== organisationWebsite)
                requestData['website'] = organisationWebsite;

            if(initialState['information'] !== organisationInformation)
                requestData['information'] = organisationInformation;

            if(initialState['withWeightage'] !== withWeightage)
                requestData['withWeightage'] = withWeightage;

            if(JSON.stringify(initialEnabledServices) !== JSON.stringify(enabledServices))
                requestData['enabledServices'] = enabledServices;

            if(initialState['interviewFocusOn'] !== interviewFocusOn.code)
                requestData['interviewFocusOn'] = interviewFocusOn.code

            if(organisationLogo){
                const fileName = new Date().getTime() + '-' +  organisationName + '-' + organisationLogo?.name;
                const presignUrl = await CommonService.generatePresignUrlForCompanyLogos(fileName);
                const {url} = presignUrl.data.data;
                await axios.put(url, organisationLogo, { headers: { 'x-ms-blob-type': 'BlockBlob'} });

                requestData['logo'] = extractCompanyLogosFilePath(url, '-');
            }
            
            if(Object.keys(requestData)?.length === 0){
                navigateTo('/dashboard/organisations-management')
                return;
            }
            
            const response = await OrganizationService.updateOrganization(id, requestData);
            if(response.status === 200){
                setLoading(false);
                showSuccessMessage({
                    summary: 'Updated organisation', 
                    detail: 'Successfully updated organisation'
                })
                navigateTo('/dashboard/organisations-management')
            }
        } catch (error) {
            setLoading(false);
            const message = error?.response?.data?.message;
            showErrorMessage({
                summary: 'Failed', 
                detail: message || 'Failed to update organization'
            })
        }
    }
  
    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className={`${loading ? 'visible': 'hidden'} h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center`}>
                <div className='w-40'>
                    <LoadingComponent />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-primary cursor-pointer">
                    <MdKeyboardArrowLeft onClick={goBack} size={26} />
                    <h1 className='text-xl text-primary font-bold'>Update Organisation</h1>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-3'>
                <div className='flex justify-between gap-10'>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <label className="text-primary font-semibold text-sm" htmlFor="organisationName">Organisation Name</label>
                        <InputText 
                            id="organisationName"
                            value={organisationName}
                            placeholder="Enter Organisation Name" 
                            onChange={handleInput.bind(this, 'organisationName')}
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['organisationName'] || (!organisationName || organisationName?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['organisationName']}</span>}
                    </div>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <label className="text-primary font-semibold text-sm" htmlFor="industry">Industry</label>
                        <InputText 
                            id="industry"
                            value={industry}
                            placeholder="Enter industry"
                            onChange={handleInput.bind(this, 'industry')} 
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['industry'] || (!industry || industry?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['industry']}</span>}
                    </div>
                </div>
                <div className="flex-col flex gap-2 w-[45%]">
                    <label className="text-primary font-semibold text-sm" htmlFor="organisationWebsite">Organisation's Website</label>
                    <InputText 
                        id="organisationWebsite"
                        value={organisationWebsite}
                        placeholder="Enter Organisation Website"
                        onChange={handleInput.bind(this, 'organisationWebsite')} 
                        className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                    />
                    {(error['organisationWebsite'] || (!organisationWebsite || organisationWebsite?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['organisationWebsite']}</span>}
                </div>
                <div className="flex-col flex gap-2">
                    <label className="text-primary font-semibold text-sm" htmlFor="organisationInformation">Organisation's Information</label>
                    <InputTextarea 
                        id="organisationInformation"
                        value={organisationInformation}
                        placeholder="Enter Organisation Information"
                        onChange={handleInput.bind(this, 'organisationInformation')} 
                        className="border-l_border border-[1px] min-h-40 p-2 text-sm font-normal"
                    />
                    {(error['organisationInformation'] || (!organisationInformation || organisationInformation?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['organisationInformation']}</span>}
                </div>
                <div className="flex-row flex justify-between items-center">
                    <div className='flex flex-col gap-2 w-2/3'>
                        <label className="text-primary font-semibold text-sm" htmlFor="organisationLogo">Organisation logo</label>
                        <span className={`${organisationLogo ? 'visible': 'hidden'} overflow-hidden line-clamp-1 text-xs text-blue`}>{organisationLogo?.name}</span>
                        <div
                            onClick={() => fileInputRef.current.click()} 
                            className="border-blue border cursor-pointer w-36 text-blue px-2 h-9 rounded font-semibold text-xs flex items-center gap-4 hover:bg-blue hover:text-white">
                            <span>Upload logo</span>
                            <AiOutlineUpload size={16}/>
                        </div>
                        <input 
                            type="file" 
                            ref={fileInputRef} 
                            className="hidden"
                            onChange={handleFileChange} 
                            accept="image/png, image/jpeg, image/jpg"
                        />
                        {(error['organisationLogo'] || !organisationLogo) && <span className="text-dr font-normal text-xs">{error['organisationLogo']}</span>}
                    </div>
                    <div className='w-1/3 flex justify-end'>
                        <img
                            alt='logo'
                            className='h-16 w-16'
                            src={imagePreview}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-5'>
                <div className="flex-col flex gap-2">
                    <label className="text-primary font-semibold text-sm" htmlFor="greetingMessage">Greeting Message</label>
                    <InputTextarea 
                        id="greetingMessage"
                        value={greetingMessage}
                        placeholder="Enter the greeting message the candidate will receive from the bot upon joining the interview."
                        onChange={handleInput.bind(this, 'greetingMessage')} 
                        className="border-l_border border-[1px] min-h-20 p-2 text-sm font-normal"
                    />
                </div>
                
                <div className='flex'>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <label 
                            className="text-primary font-semibold text-sm" 
                            htmlFor="expiry">
                            Interivew Link Expire time (In days)
                        </label>
                        <div className='flex items-center gap-2'>
                            <InputText
                                min={1}
                                max={31}
                                maxLength={2}
                                value={inviteExpiryDays}
                                onChange={handleInput.bind(this, 'inviteExpiryDays')} 
                                className="border-l_border font-medium border-[1px] w-20 h-10 text-center text-sm"
                            />
                            <span className='text-sm text-darkGray'>Days</span>
                        </div>
                        {(error['inviteExpiryDays'] || (!inviteExpiryDays || inviteExpiryDays > 0)) 
                            && <span className="text-dr font-normal text-xs">{error['inviteExpiryDays']}</span>}
                    </div>
                    <div className="flex-col flex gap-2 w-[45%]">
                        <label 
                            className="text-primary font-semibold text-sm" 
                            htmlFor="followupDelayHours">
                            Follow up for Interview invite (In hours)
                        </label>
                        <div className='flex items-center gap-2'>
                            <InputText
                                min={12}
                                max={720}
                                maxLength={3}
                                value={followupDelayHours}
                                onChange={handleInput.bind(this, 'followupDelayHours')} 
                                className="border-l_border font-medium border-[1px] w-20 h-10 text-center text-sm"
                            />
                            <span className='text-sm text-darkGray'>Hours</span>
                        </div>
                        {(error['followupDelayHours'] || (!followupDelayHours || followupDelayHours > 0)) 
                            && <span className="text-dr font-normal text-xs">{error['followupDelayHours']}</span>}
                    </div>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-5'>
                <h1 className='text-lg text-primary font-bold'>Skills Weightage</h1>
                <div className='flex gap-5 flex-wrap'>
                    <div className="flex gap-3 items-center">
                        <CheckboxComponent
                            value={!withWeightage}
                            setValue={(label, value) => setWithWeightage(false)}
                        />
                        <label className="text-sm font-medium w-full mr-2">Without skills Weightage</label>
                    </div>
                    <div className="flex gap-3 items-center">
                        <CheckboxComponent
                            value={withWeightage}
                            setValue={(label, value) => setWithWeightage(true)}
                        />
                        <label className="text-sm font-medium w-full mr-2">With skills Weightage</label>
                    </div>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col'>
                <h1 className='text-lg text-primary font-bold'>Interview Focus On</h1>
                <div className="flex-1 flex gap-2 justify-between items-center mt-4">
                    <label 
                        className="text-darkGray font-medium text-base" 
                        htmlFor="selectedBotVoice">
                        Technical Question Focus On
                    </label>
                    <Dropdown
                        optionLabel="name"   
                        value={interviewFocusOn}
                        className="border text-sm"
                        options={interviewFocusOnList} 
                        placeholder="Interview Focus On"
                        onChange={(e) => setInterviewFocusOn(e.value)} 
                        pt={{
                            root: {className: 'flex items-center pr-2'},
                            input: {className: 'text-sm h-10 py-2.5 font-medium'},
                            item: {className: 'text-xs h-10'},
                            clearIcon: {className: 'text-xs h-3 w-3'},
                            trigger: {className: 'text-xs h-3 w-3'}
                        }}
                    />
                </div>
                {interviewFocusOn.description && <span className="text-do text-sm">Note: {interviewFocusOn.description}</span> }
            </div>
            <OrganizationAvatarBotVoiceComponent 
                selectedAvatar={selectedAvatar}
                selectedBotVoice={selectedBotVoice}
                selectBotVoice={setSelectedBotVoice}
                selectAvatar={setSelectedAvatar}
            />
            <div className='bg-white p-5 rounded-md flex flex-col gap-5'>
                <h1 className='text-lg text-primary font-bold'>Interview Options</h1>
                <OrganizationServiceOptions
                    selectedOptions={enabledServices}
                    handleChanges={handleInterviewOptions}
                />
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-10'>
                <h1 className='text-lg text-primary font-bold'>Prompt Mapping</h1>
                <div className='flex flex-col gap-4'>
                    <h1>Video Profiling</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="videoProfilingQuestionGenerationPromptId">
                                Video Profiling Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.videoProfilingQuestionGenerationPromptId} 
                                defaultValue={promptIds?.videoProfilingQuestionGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'videoProfilingQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['videoProfilingQuestionGenerationPromptId'] && !promptIds?.videoProfilingQuestionGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['videoProfilingQuestionGenerationPromptId']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="videoProfilingAcknowledgmentGenerationPromptId">
                                Video Profiling Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.videoProfilingAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.videoProfilingAcknowledgmentGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'videoProfilingAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['videoProfilingAcknowledgmentGenerationPromptId'] && !promptIds?.videoProfilingAcknowledgmentGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['videoProfilingAcknowledgmentGenerationPromptId']}</span>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <h1>HR Interview</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="hrInterviewQuestionGenerationPromptId">
                                HR Interview Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.hrInterviewQuestionGenerationPromptId} 
                                defaultValue={promptIds?.hrInterviewQuestionGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'hrInterviewQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['hrInterviewQuestionGenerationPromptId'] && !promptIds?.hrInterviewQuestionGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['hrInterviewQuestionGenerationPromptId']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="hrInterviewAcknowledgmentGenerationPromptId">
                                HR Interview Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.hrInterviewAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.hrInterviewAcknowledgmentGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'hrInterviewAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['hrInterviewAcknowledgmentGenerationPromptId'] && !promptIds?.hrInterviewAcknowledgmentGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['hrInterviewAcknowledgmentGenerationPromptId']}</span>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <h1>Technical Interview</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="technicalInterviewQuestionGenerationPromptId">
                                Technical Interview Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.technicalInterviewQuestionGenerationPromptId} 
                                defaultValue={promptIds?.technicalInterviewQuestionGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'technicalInterviewQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['technicalInterviewQuestionGenerationPromptId'] && !promptIds?.technicalInterviewQuestionGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['technicalInterviewQuestionGenerationPromptId']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="technicalInterviewAcknowledgmentGenerationPromptId">
                                Technical Interview Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.technicalInterviewAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.technicalInterviewAcknowledgmentGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'technicalInterviewAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['technicalInterviewAcknowledgmentGenerationPromptId'] && !promptIds?.technicalInterviewAcknowledgmentGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['technicalInterviewAcknowledgmentGenerationPromptId']}</span>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <h1>Exit Interview</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="exitInterviewQuestionGenerationPromptId">
                                Exit Interview Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.exitInterviewQuestionGenerationPromptId} 
                                defaultValue={promptIds?.exitInterviewQuestionGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'exitInterviewQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['exitInterviewQuestionGenerationPromptId'] && !promptIds?.exitInterviewQuestionGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['exitInterviewQuestionGenerationPromptId']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="exitInterviewAcknowledgmentGenerationPromptId">
                                Exit Interview Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.exitInterviewAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.exitInterviewAcknowledgmentGenerationPromptId}
                                placeholder="Select Prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'exitInterviewAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['exitInterviewAcknowledgmentGenerationPromptId'] && !promptIds?.exitInterviewAcknowledgmentGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['exitInterviewAcknowledgmentGenerationPromptId']}</span>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <h1>BPO HR Interview</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="bpoHRInterviewQuestionGenerationPromptId">
                                BPO HR Interview Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                placeholder="Select prompt" 
                                value={promptIds?.bpoHRInterviewQuestionGenerationPromptId} 
                                className="h-10 border-l_border border-[1px]"
                                defaultValue={promptIds?.bpoHRInterviewQuestionGenerationPromptId}
                                onChange={handlePromptIdChange.bind(this, 'bpoHRInterviewQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['bpoHRInterviewQuestionGenerationPromptId'] && !promptIds?.bpoHRInterviewQuestionGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['bpoHRInterviewQuestionGenerationPromptId']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="bpoHRInterviewAcknowledgmentGenerationPromptId">
                                BPO HR Interview Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.bpoHRInterviewAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.bpoHRInterviewAcknowledgmentGenerationPromptId}
                                placeholder="Select Prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'bpoHRInterviewAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['bpoHRInterviewAcknowledgmentGenerationPromptId'] && !promptIds?.bpoHRInterviewAcknowledgmentGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['bpoHRInterviewAcknowledgmentGenerationPromptId']}</span>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <h1>BPO OPS Text Interview</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="bpoOpsTextInterviewQuestionGenerationPromptId">
                                BPO OPS Text Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.bpoOpsTextInterviewQuestionGenerationPromptId} 
                                className="h-10 border-l_border border-[1px]"
                                defaultValue={promptIds?.bpoOpsTextInterviewQuestionGenerationPromptId}
                                placeholder="Select Prompt" 
                                onChange={handlePromptIdChange.bind(this, 'bpoOpsTextInterviewQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['bpoOpsTextInterviewQuestionGenerationPromptId'] && !promptIds?.bpoOpsTextInterviewQuestionGenerationPromptId) && <span className="text-dr font-normal text-xs">{error['bpoOpsTextInterviewQuestionGenerationPromptId']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="bpoOpsTextInterviewAcknowledgmentGenerationPromptId">
                                BPO OPS Text Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.bpoOpsTextInterviewAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.bpoOpsTextInterviewAcknowledgmentGenerationPromptId}
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={handlePromptIdChange.bind(this, 'bpoOpsTextInterviewAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['bpoOpsTextInterviewAcknowledgmentGenerationPromptId'] && !promptIds?.bpoOpsTextInterviewAcknowledgmentGenerationPromptId) && 
                                <span className="text-dr font-normal text-xs">{error['bpoOpsTextInterviewAcknowledgmentGenerationPromptId']}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <h1>BPO OPS Voice Interview</h1>
                    <div className='flex gap-5'>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="bpoOpsVoiceInterviewQuestionGenerationPromptId">
                                BPO OPS Voice Question Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                value={promptIds?.bpoOpsVoiceInterviewQuestionGenerationPromptId} 
                                className="h-10 border-l_border border-[1px]"
                                defaultValue={promptIds?.bpoOpsVoiceInterviewQuestionGenerationPromptId}
                                placeholder="Select Prompt" 
                                onChange={handlePromptIdChange.bind(this, 'bpoOpsVoiceInterviewQuestionGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['bpoOpsVoiceInterviewQuestionGenerationPromptId'] && !promptIds?.bpoOpsVoiceInterviewQuestionGenerationPromptId) && 
                                <span className="text-dr font-normal text-xs">{error['bpoOpsVoiceInterviewQuestionGenerationPromptId']}</span>
                            }
                        </div>
                        <div className="flex-1 flex flex-col gap-2 w-1/3">
                            <label 
                            className="text-darkGray font-medium text-sm" 
                                htmlFor="bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId">
                                BPO OPS Voice Acknowledgment Generation
                            </label>
                            <Dropdown 
                                checkmark={true}
                                options={prompts} 
                                optionLabel="title"
                                highlightOnSelect={false} 
                                placeholder="Select prompt" 
                                className="h-10 border-l_border border-[1px]"
                                value={promptIds?.bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId} 
                                defaultValue={promptIds?.bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId}
                                onChange={handlePromptIdChange.bind(this, 'bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId')}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {(error['bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId'] && !promptIds?.bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId) && 
                                <span className="text-dr font-normal text-xs">{error['bpoOpsVoiceInterviewAcknowledgmentGenerationPromptId']}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-3'>
                <h1 className='text-lg text-primary font-bold'>Admin account detail</h1>
                <div className='flex gap-5 flex-wrap py-5'>
                    <div className="flex-col flex w-[30%] gap-2">
                        <label className="text-primary font-semibold text-xs" htmlFor="firstName">First Name</label>
                        <InputText 
                            id="firstName"
                            disabled
                            value={account?.firstName}
                            placeholder="Enter First Name" 
                            onChange={handleAccountInput.bind(this, 'firstName')}
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['firstName'] || (!account?.firstName || account?.firstName?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['firstName']}</span>}
                    </div>
                    <div className="flex-col flex w-[30%] gap-2">
                        <label className="text-primary font-semibold text-xs" htmlFor="lastName">Last Name</label>
                        <InputText 
                            id="lastName"
                            disabled
                            value={account?.lastName}
                            placeholder="Enter Last Name" 
                            onChange={handleAccountInput.bind(this, 'lastName')}
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['lastName'] || (!account?.lastName || account?.lastName?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['lastName']}</span>}
                    </div>
                    <div className="flex-col flex w-[30%] gap-2">
                        <label className="text-primary font-semibold text-xs" htmlFor="email">Email</label>
                        <InputText 
                            id="email"
                            disabled
                            value={account?.email}
                            placeholder="Enter Email"
                            onChange={handleAccountInput.bind(this, 'email')} 
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['email'] || (!account?.email || account?.email?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['email']}</span>}
                    </div>
                    <div className="flex-col flex w-[30%] gap-2">
                        <label className="text-primary font-semibold text-xs" htmlFor="mobile">Mobile Number</label>
                        <InputText 
                            id="mobile"
                            disabled
                            value={account?.mobile}
                            placeholder="Enter Mobile Number" 
                            onChange={handleAccountInput.bind(this, 'mobile')} 
                            className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                        />
                        {(error['mobile'] || (!account?.mobile || account?.mobile?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['mobile']}</span>}
                    </div>          
                </div>
            </div>
            <button 
                onClick={submit}
                disabled={!isValuesChanged()}
                className={`bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-5 ${isValuesChanged() ? 'opacity-100': 'opacity-40'}`}>
                Update
            </button>
        </div>
    )
}